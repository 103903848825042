import styled from '@emotion/styled';
import ToolbarMui from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import ColumnChooser from './ColumnChooser';
import { useDataGridFlexSales } from './DataGridFlexSalesProvider';
import Export from './Export';

const ToolbarStyled = styled(ToolbarMui)`
  background-color: ${props => props.theme.palette.primary.main};
  justify-content: space-between;
  color: white;

  button {
    color: inherit;
  }

  &.Toolbar-toolbar {
    border: none;
  }

  @media (min-width: 600px) {
    padding-left: ${props => props.theme.spacing(2)};
    padding-right: ${props => props.theme.spacing(2)};
  }
`;

const DataGridTitle = () => {
  const { handler } = useDataGridFlexSales();
  const [title, setTitle] = useState(handler.titles.tableTitle);

  useEffect(() => {
    const listenerTitle = handler.addEventListener('title', ({ titles }) =>
      setTitle(titles.tableTitle)
    );

    return () => {
      listenerTitle.dispose();
    };
  }, [handler]);

  return <Typography variant="h6">{title}</Typography>;
};

export const Toolbar = () => (
  <ToolbarStyled variant="dense">
    <DataGridTitle />
    <div>
      <ColumnChooser />
      <Export />
    </div>
  </ToolbarStyled>
);
