import Grid from '@mui/material/Grid';
import {
  findReportTenderRequested,
  reportFilterTenderGroupBySelector,
  reportTenderLoadingSelector,
  changeFilterTenderGroupBy,
} from '../state';

import {
  ReportFilterActions,
  ReportFilters,
  ReportFiltersProps,
  CountryFilter,
  HourRangeFilter,
  PosFilter,
  SegmentsFilter,
  StoreFilter,
  BusinessDateRangeFilter,
  AccountingDateRangeFilter,
  OperationDateRangeFilter,
  SalesTypesFilter,
  PaymentTypesFilter,
  OperationTypeFilter,
  TimeBandFilter,
} from './ReportFilters';

import { AppPermissions, ReportFilterTenderGroupBy } from '../types';
import { PermissionsProvider } from './Permissions';

const groupByConfig: ReportFiltersProps<ReportFilterTenderGroupBy>['groupByConfig'] = {
  options: {
    countryAcronym: true,
    tenderName: false,
    storeAcronym: false,
    businessDate: false,
    accountingDate: false,
    operationDate: false,
    posNum: false,
    saleType: false,
    timeBand: false,
    timeRange: false,
    posType: false,
    operationTypeName: false,
  },
  action: changeFilterTenderGroupBy,
  selector: reportFilterTenderGroupBySelector,
};

const dialogFilters = (
  <>
    <SegmentsFilter />
    <SalesTypesFilter />
    <PosFilter />
    <HourRangeFilter />
    <TimeBandFilter />
    <OperationTypeFilter />
    <OperationDateRangeFilter />
    <AccountingDateRangeFilter />
  </>
);

const onedriveAction = () => findReportTenderRequested(true);

const SalesTotalsSearch = () => (
  <PermissionsProvider resourcePermissions={[AppPermissions.TENDER]}>
    <ReportFilters
      groupByConfig={groupByConfig}
      loadingSelector={reportTenderLoadingSelector}
      applyAction={findReportTenderRequested}
      dialogFilters={dialogFilters}
      dialogFiltersApplied={[
        'segments',
        'salesTypes',
        'pos',
        'hourRange',
        'operationType',
        'startOperationDate',
        'endOperationDate',
        'startAccountingDate',
        'endAccountingDate',
      ]}
      onedriveAction={onedriveAction}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <StoreFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <PaymentTypesFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={5} xl={3}>
          <BusinessDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </ReportFilters>
  </PermissionsProvider>
);

export default SalesTotalsSearch;
