import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import {
  changeFilterGroupBy,
  findSalesRequested,
  reportFilterCountryCodeSelector,
  reportFilterGroupBySelector,
  reportSalesLoadingSelector,
} from '../state';

import {
  AccountingDateRangeFilter,
  BusinessDateRangeFilter,
  CountryFilter,
  HourRangeFilter,
  OperationDateRangeFilter,
  PodFilter,
  PosFilter,
  ReportFilterActions,
  ReportFilters,
  ReportFiltersProps,
  SalesTypesFilter,
  SegmentsFilter,
  StoreFilter,
  TimeBandFilter,
} from './ReportFilters';

import { AppPermissions, ReportFilterGroupBy } from '../types';
import { PermissionsProvider } from './Permissions';

const groupByConfig: ReportFiltersProps<ReportFilterGroupBy>['groupByConfig'] = {
  options: {
    countryAcronym: true,
    storeAcronym: false,
    podPod: false,
    businessDate: false,
    accountingDate: false,
    operationDate: false,
    posNum: false,
    saleType: false,
    timeBand: false,
    timeRange: false,
    posType: false,
    regionalMgmtId: false,
    regionId: false,
    mgmtId: false,
    supervisionId: false,
    agreementId: false,
    companyId: false,
  },
  action: changeFilterGroupBy,
  selector: reportFilterGroupBySelector,
};

const dialogFilters = (
  <>
    <BusinessDateRangeFilter />
    <OperationDateRangeFilter />
  </>
);

const onedriveAction = () => findSalesRequested(true);

const GridBr = () => {
  const countryCode = useSelector(reportFilterCountryCodeSelector);

  if (countryCode !== 'BR') return null;

  return (
    <Grid item xs={12} md={3} lg={2} xl={2}>
      <PodFilter />
    </Grid>
  );
};

const SalesTotalsSearch = () => (
  <ReportFilters
    groupByConfig={groupByConfig}
    loadingSelector={reportSalesLoadingSelector}
    applyAction={findSalesRequested}
    onedriveAction={onedriveAction}
    dialogFilters={dialogFilters}
    dialogFiltersApplied={[
      'startOperationDate',
      'endOperationDate',
      'startBusinessDate',
      'endBusinessDate',
    ]}
    showOpStructureFilters
  >
    <PermissionsProvider resourcePermissions={[AppPermissions.SALES_TOTALS]}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <CountryFilter fullWidth />
        </Grid>
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <StoreFilter />
        </Grid>
        <GridBr />
        <Grid item xs={12} md={3} lg={2} xl={2}>
          <PosFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={4} xl={2}>
          <SalesTypesFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <SegmentsFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <TimeBandFilter />
        </Grid>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <HourRangeFilter />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <AccountingDateRangeFilter spacing={1} />
        </Grid>
        <Grid item xs={12} md lg xl>
          <ReportFilterActions />
        </Grid>
      </Grid>
    </PermissionsProvider>
  </ReportFilters>
);

export default SalesTotalsSearch;
