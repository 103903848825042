import { ReportFilterGroupBy, SalesByProduct, SaleType } from '../../types';
import { DataGridHandler } from '../DataGridFlexSales';

export interface SalesByProductDataGridContext {
  t: (key: string | string[]) => string;
  groupBy: ReportFilterGroupBy[];
  country: string;
  saleTypeOptions: SaleType[];
}

const initialContext: SalesByProductDataGridContext = {
  t: () => '',
  groupBy: [],
  country: '',
  saleTypeOptions: [],
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<SalesByProduct, SalesByProductDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
    available: ({ groupBy }) => groupBy.includes('storeAcronym'),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
    available: ({ groupBy }) => groupBy.includes('businessDate'),
  });

  handler.addColumn({
    accessor: 'accountingDate',
    type: 'date',
    title: ({ t }) => t('table.columns.accounting_date'),
    available: ({ groupBy }) => groupBy.includes('accountingDate'),
  });

  handler.addColumn({
    accessor: 'operationDate',
    type: 'date',
    title: ({ t }) => t('table.columns.operation_date'),
    available: ({ groupBy }) => groupBy.includes('operationDate'),
  });

  handler.addColumn({
    name: 'saleTypeWh',
    type: 'string',
    title: ({ t }) => t('table.columns.sale_type_wh'),
    valueGetter: (row, { saleTypeOptions }) => {
      if (!row.saleTypeWh) return '-';
      return saleTypeOptions.find(i => Number(i.saleTypeId) === Number(row.saleTypeWh))
        ?.saleTypeDescription;
    },
    available: ({ groupBy }) => groupBy.includes('saleType'),
  });

  handler.addColumn({
    accessor: 'posType',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_type'),
    available: ({ groupBy }) => groupBy.includes('posType'),
  });

  handler.addColumn({
    accessor: 'posNum',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_num'),
    available: ({ groupBy }) => groupBy.includes('posNum'),
  });

  handler.addColumn({
    accessor: 'dayPart',
    type: 'string',
    title: ({ t }) => t('table.columns.day_part'),
    available: ({ groupBy }) => groupBy.includes('timeRange'),
  });

  handler.addColumn({
    accessor: 'timeBand',
    type: 'string',
    title: ({ t }) => t('table.columns.time_band'),
    available: ({ groupBy }) => groupBy.includes('timeBand'),
  });

  handler.addColumn({
    accessor: 'plu',
    type: 'number',
    title: ({ t }) => t('table.columns.plu'),
    available: ({ groupBy }) => groupBy.includes('plu'),
  });

  handler.addColumn({
    accessor: 'grossSalePnp',
    type: 'number',
    title: ({ t }) => t('sale-by-product.columns.gross_sale_pnp'),
  });

  handler.addColumn({
    accessor: 'netSalePnp',
    type: 'number',
    title: ({ t }) => t('sale-by-product.columns.net_sale_final_pnp'),
  });

  handler.addColumn({
    accessor: 'grossSaleP',
    type: 'number',
    title: ({ t }) => t('sale-by-product.columns.gross_sale_p'),
  });

  handler.addColumn({
    accessor: 'netSaleP',
    type: 'number',
    title: ({ t }) => t('sale-by-product.columns.net_sale_p'),
  });

  handler.addColumn({
    accessor: 'grossSaleNp',
    type: 'number',
    title: ({ t }) => t('sale-by-product.columns.gross_sale_np'),
  });

  handler.addColumn({
    accessor: 'netSaleNp',
    type: 'number',
    title: ({ t }) => t('sale-by-product.columns.net_sale_np'),
  });

  handler.addColumn({
    accessor: 'grossSaleThirdParty',
    type: 'number',
    title: ({ t }) => t('sale-by-product.columns.gross_sale_third_party'),
  });

  handler.addColumn({
    accessor: 'netSaleThirdParty',
    type: 'number',
    title: ({ t }) => t('sale-by-product.columns.net_sale_third_party'),
  });

  handler.addColumn({
    accessor: 'grossSalePos',
    type: 'number',
    title: ({ t }) => t('sale-by-product.columns.gross_sale_pos'),
  });

  handler.addColumn({
    accessor: 'netSalePos',
    type: 'number',
    title: ({ t }) => t('sale-by-product.columns.net_sale_pos'),
  });

  handler.addColumn({
    accessor: 'quantitySold',
    type: 'number',
    title: ({ t }) => t('table.columns.quantity_sold'),
    available: ({ groupBy }) => groupBy.includes('plu'),
  });
  handler.addColumn({
    accessor: 'quantitySoldLocal',
    type: 'number',
    title: ({ t }) => t('table.columns.quantity_sold_local'),
    available: ({ groupBy }) => groupBy.includes('plu'),
  });
  handler.addColumn({
    accessor: 'quantitySoldDelivery',
    type: 'number',
    title: ({ t }) => t('table.columns.quantity_sold_delivery'),
    available: ({ groupBy }) => groupBy.includes('plu'),
  });
  handler.addColumn({
    accessor: 'quantityWaste',
    type: 'number',
    title: ({ t }) => t('table.columns.quantity_waste'),
    available: ({ groupBy }) => groupBy.includes('plu'),
  });
  handler.addColumn({
    accessor: 'quantityPromo',
    type: 'number',
    title: ({ t }) => t('table.columns.quantity_promo'),
    available: ({ groupBy }) => groupBy.includes('plu'),
  });
  handler.addColumn({
    accessor: 'quantityMeal',
    type: 'number',
    title: ({ t }) => t('table.columns.quantity_meal'),
    available: ({ groupBy }) => groupBy.includes('plu'),
  });

  return handler;
};
