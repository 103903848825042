import { DiscountsJoin, GCsByProduct, SaleType, Translation } from '../../types';
import { DataGridHandler, commonColProps } from '../DataGridFlexSales';

export interface GCsByProductDataGridContext {
  t: Translation;
  country: string;
  saleTypeOptions: SaleType[];
  discountsJoin: DiscountsJoin[];
}

const initialContext: GCsByProductDataGridContext = {
  t: () => '',
  country: '',
  saleTypeOptions: [],
  discountsJoin: [],
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<GCsByProduct, GCsByProductDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    accessor: 'store',
    type: 'string',
    title: ({ t, country }) => t([`table.columns.${country}.store`, 'table.columns.store']),
  });

  handler.addColumn({
    accessor: 'businessDate',
    type: 'date',
    title: ({ t }) => t('table.columns.business_date'),
  });

  handler.addColumn({
    accessor: 'accountingDate',
    type: 'date',
    title: ({ t }) => t('table.columns.accounting_date'),
  });

  handler.addColumn({
    accessor: 'operationDate',
    type: 'date',
    title: ({ t }) => t('table.columns.operation_date'),
  });

  handler.addColumn({
    accessor: 'posNum',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_num'),
  });

  handler.addColumn({
    accessor: 'transactionType',
    type: 'number',
    title: ({ t }) => t('table.columns.transaction_type'),
  });

  handler.addColumn(commonColProps.saleType);

  handler.addColumn({
    accessor: 'posType',
    type: 'string',
    title: ({ t }) => t('table.columns.pos_type'),
  });

  handler.addColumn({
    accessor: 'fiscalReceiptNum',
    type: 'string',
    title: ({ t }) => t('table.columns.fiscal_receipt_num'),
  });

  handler.addColumn({
    accessor: 'seqNumber',
    type: 'string',
    title: ({ t }) => t('table.columns.seq_num'),
  });

  handler.addColumn({
    accessor: 'transactionAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.transaction_amt'),
  });

  handler.addColumn({
    accessor: 'saleDiscountId',
    type: 'number',
    available: ({ discountsJoin }) => discountsJoin.includes('sale'),
    title: ({ t }) => t('table.columns.sale_discount_id'),
  });

  handler.addColumn({
    accessor: 'saleDiscountDescription',
    type: 'string',
    available: ({ discountsJoin }) => discountsJoin.includes('sale'),
    title: ({ t }) => t('table.columns.sale_discount_description'),
  });

  handler.addColumn({
    accessor: 'saleDiscountRate',
    type: 'number',
    available: ({ discountsJoin }) => discountsJoin.includes('sale'),
    title: ({ t }) => t('table.columns.sale_discount_rate'),
  });

  handler.addColumn({
    accessor: 'saleDiscountAmount',
    type: 'number',
    available: ({ discountsJoin }) => discountsJoin.includes('sale'),
    title: ({ t }) => t('table.columns.sale_discount_amount'),
  });

  handler.addColumn({
    accessor: 'itemDiscountId',
    type: 'number',
    available: ({ discountsJoin }) => discountsJoin.includes('item'),
    title: ({ t }) => t('table.columns.item_discount_id'),
  });

  handler.addColumn({
    accessor: 'itemDiscountDescription',
    type: 'string',
    available: ({ discountsJoin }) => discountsJoin.includes('item'),
    title: ({ t }) => t('table.columns.item_discount_description'),
  });

  handler.addColumn({
    accessor: 'itemDiscountRate',
    type: 'number',
    available: ({ discountsJoin }) => discountsJoin.includes('item'),
    title: ({ t }) => t('table.columns.item_discount_rate'),
  });

  handler.addColumn({
    accessor: 'itemDiscountAmount',
    type: 'number',
    available: ({ discountsJoin }) => discountsJoin.includes('item'),
    title: ({ t }) => t('table.columns.item_discount_amount'),
  });

  handler.addColumn({
    accessor: 'discountOnTotalAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.discount_on_total_amt'),
  });

  handler.addColumn({
    accessor: 'dayPart',
    type: 'string',
    title: ({ t }) => t('table.columns.day_part'),
  });

  handler.addColumn({ accessor: 'plu', type: 'number', title: ({ t }) => t('table.columns.plu') });

  handler.addColumn({
    accessor: 'shortName',
    type: 'string',
    title: ({ t }) => t('table.columns.short_name'),
  });

  handler.addColumn({
    accessor: 'itemsQty',
    type: 'number',
    title: ({ t }) => t('table.columns.items_qty'),
  });

  handler.addColumn({
    accessor: 'unitPriceAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.unit_price_amt'),
  });

  handler.addColumn({
    accessor: 'itemStatusName',
    type: 'string',
    title: ({ t }) => t('table.columns.item_status_name'),
  });

  handler.addColumn({
    accessor: 'nonProductPrice',
    type: 'number',
    title: ({ t }) => t('table.columns.non_product_price'),
  });

  handler.addColumn({
    accessor: 'itemTotalAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.item_total_amt'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'taxAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.tax_amt'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'ventaNpNetaIva',
    type: 'number',
    title: ({ t, country }) =>
      t([`table.columns.${country}.venta_np_neta_iva`, 'table.columns.venta_np_neta_iva']),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'taxNpAmt',
    type: 'number',
    title: ({ t }) => t('table.columns.tax_np_amt'),
    total: { type: 'sum' },
  });

  handler.addColumn({
    accessor: 'thirdpartyMenuItem',
    type: 'boolean',
    title: ({ t }) => t('table.columns.thirdparty_menu_item'),
  });

  handler.addColumn({
    accessor: 'clientMcId',
    type: 'string',
    title: ({ t }) => t('table.columns.client_mc_id'),
  });

  handler.addColumn({
    accessor: 'program',
    type: 'string',
    title: ({ t }) => t('table.columns.program'),
  });

  handler.addColumn({
    accessor: 'digitalChannel',
    type: 'number',
    title: ({ t }) => t('table.columns.digital_channel'),
  });

  return handler;
};
