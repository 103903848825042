import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SalesByProduct } from '../../types';
import {
  downloadReportPdf,
  reportFilterCountryCodeSelector,
  reportFilterSalesProductGroupBySelector,
  reportMissingClosingTotalMissingClosingTotalSelector,
  reportSalesByProductSelector,
  reportSalesTypesSelector,
} from '../../state';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { SalesByProductDataGridContext } from './handlerFactory';

const DataGridTitle = () => {
  const { t } = useTranslation();
  const { loaded, data: totals } = useSelector(
    reportMissingClosingTotalMissingClosingTotalSelector
  );
  let title = t('sale-by-product.title');

  if (loaded && totals !== null) {
    title += ` (${totals} ${t('sale-totals.labels.missing')})`;
  }
  return <span>{title}</span>;
};

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<SalesByProduct, SalesByProductDataGridContext>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const country = useSelector(reportFilterCountryCodeSelector);
  const salesResource = useSelector(reportSalesByProductSelector);
  const groupBy = useSelector(reportFilterSalesProductGroupBySelector);
  const titles = useMemo(
    () => ({ title: t('sale-by-product.title'), tableTitle: <DataGridTitle /> }),
    [t]
  );
  const { data: saleTypeOptions } = useSelector(reportSalesTypesSelector);

  const { loading, data } = salesResource;

  const gridContext = useMemo(
    () => ({ t, country, groupBy, saleTypeOptions }),
    [t, country, groupBy, saleTypeOptions]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(titles);
  handler.setLoading(loading);

  useEffect(() => {
    handler.setExportConfig({
      PDF: {
        generator: (table, title) => {
          dispatch(
            downloadReportPdf({
              locale: handler.locale,
              t,
              table,
              title,
              includeMissingClosingTable: true,
              parameters: [
                'countryCode',
                'stores',
                'pos',
                'salesTypes',
                'segments',
                'timeBand',
                'hourRange',
                'startAccountingDate',
                'endAccountingDate',
                'startOperationDate',
                'endOperationDate',
                'startBusinessDate',
                'endBusinessDate',
                'plu',
                'salesProductGroupBy',
              ],
            })
          );
        },
        isDispatchAction: true,
      },
    });
  }, [handler, dispatch, t]);

  return null;
};

const SalesByProductDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default SalesByProductDataGrid;
