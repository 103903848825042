import styled from '@emotion/styled';
import { AutocompleteValue, Divider } from '@mui/material';
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { HTMLAttributes, ReactNode, SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { onlyUnique } from '../../helpers';

export interface MultiSelectProps<TData>
  extends Omit<
    AutocompleteProps<TData, true, undefined, false>,
    | 'multiple'
    | 'autoComplete'
    | 'includeInputInList'
    | 'disableCloseOnSelect'
    | 'renderInput'
    | 'onChange'
  > {
  propDesc?: keyof TData;
  onChange: (
    value: TData[],
    event?: SyntheticEvent,
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TData>
  ) => void;
  label: ReactNode;
  translationKey?: string;
  selectAll?: boolean;
}

const TagContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SelectAll = styled.div`
  position: sticky;
  top: -${props => props.theme.spacing(1)};
  background-color: ${props => props.theme.palette.background.paper};
  z-index: 2;
`;

const MultiSelect = <TData,>({
  propDesc,
  label,
  onChange,
  fullWidth = true,
  loading,
  translationKey,
  options,
  value,
  renderGroup,
  groupBy,
  selectAll,
  ...others
}: MultiSelectProps<TData>) => {
  const { t } = useTranslation();

  const getLabel = useCallback(
    (option: TData) => {
      let label: string | TData | TData[keyof TData] = propDesc ? option[propDesc] : option;
      label = translationKey ? t(`${translationKey}.${label}`) : label;
      return label as unknown as string;
    },
    [propDesc, t, translationKey]
  );

  const renderTag = useCallback(
    (options: TData[]) => (
      <TagContainer>
        <span>{options.map(getLabel).join()}</span>
      </TagContainer>
    ),
    [getLabel]
  );

  const renderOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      option: TData,
      { selected }: AutocompleteRenderOptionState
    ) => (
      <li {...props}>
        <Checkbox checked={selected} size="small" />
        {getLabel(option)}
      </li>
    ),
    [getLabel]
  );

  const labelSort = (a: TData, b: TData) => (getLabel(a) > getLabel(b) ? 1 : -1);

  const handleSelect = useCallback(
    (
      event: SyntheticEvent,
      value: TData[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<TData>
    ) => onChange(value, event, reason, details),
    [onChange]
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <FormControl fullWidth={fullWidth}>
        <TextField {...params} label={label} variant="standard" />
      </FormControl>
    ),
    [fullWidth, label]
  );

  const handleRenderGroup = useMemo(() => {
    if (selectAll && !renderGroup && Array.isArray(value)) {
      const checkedGroup = () => options.length === value.length;

      const onClick = (_group: string, checked: boolean) => {
        const optionsSelected = !checked ? onlyUnique([...value, ...options]) : [];

        onChange(optionsSelected as AutocompleteValue<TData, true, undefined, false>);
      };

      return ({ group, key, children }: AutocompleteRenderGroupParams) => {
        const checked = checkedGroup();
        return (
          <li key={key}>
            <SelectAll>
              <span
                className={autocompleteClasses.option}
                onClick={() => onClick(group, checked)}
                role="option"
                tabIndex={-1}
                onKeyPress={e => (e.key === 'Enter' ? onClick(group, checked) : undefined)}
                aria-selected={checked}
              >
                <Checkbox checked={checked} size="small" />
                {t('shared.labels.selectAll', { length: options.length })}
              </span>
              <Divider />
            </SelectAll>
            {children}
          </li>
        );
      };
    }
    return renderGroup;
  }, [selectAll, renderGroup, value, options, onChange, t]);

  const handleGroupBy = selectAll && !groupBy ? () => '' : groupBy;

  const propsLoading = useMemo(
    () => (loading ? { popupIcon: <CircularProgress size={16} />, disabled: true } : {}),
    [loading]
  );

  return (
    <Autocomplete<TData, true, undefined, false>
      {...others}
      multiple
      disableCloseOnSelect
      value={value}
      onChange={handleSelect}
      options={(options as []).sort(labelSort)}
      renderTags={renderTag}
      noOptionsText={t('toolbar.labels.noOptionsText')}
      getOptionLabel={getLabel}
      renderOption={renderOption}
      renderInput={renderInput}
      groupBy={handleGroupBy}
      renderGroup={handleRenderGroup}
      {...propsLoading}
    />
  );
};

export default MultiSelect;
