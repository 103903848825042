import { createContext, ReactNode, useContext, useMemo } from 'react';
import { AnyAction } from 'redux';
import { AppState } from '../../state';
import { DiscountsJoin, ReportFilter } from '../../types';

interface GroupByProps<TGroupBy> {
  options: TGroupBy extends string ? Record<TGroupBy, boolean> : undefined;
  selector: (state: AppState) => Array<TGroupBy>;
  action: TGroupBy extends string ? (groupBy: TGroupBy[]) => AnyAction : undefined;
}

export interface DiscountsProps {
  options: Record<DiscountsJoin, boolean>;
  selector: (state: AppState) => DiscountsJoin[];
  action: (discountsJoin: DiscountsJoin[]) => AnyAction;
  showJoins?: boolean;
}

interface ReportFiltersContextType<TGroupBy> {
  groupByConfig?: GroupByProps<TGroupBy>;
  discountsConfig?: DiscountsProps;
  loadingSelector: (state: AppState) => boolean;
  applyAction: (() => AnyAction) | (() => AnyAction[]);
  onedriveAction?: () => AnyAction;
  dialogFilters?: ReactNode;
  dialogFiltersApplied?: (keyof ReportFilter)[];
  showOpStructureFilters?: boolean;
}

const initialState: ReportFiltersContextType<unknown> = {
  groupByConfig: undefined,
  discountsConfig: undefined,
  loadingSelector: () => false,
  applyAction: () => ({ type: '' }),
  onedriveAction: () => ({ type: '' }),
  dialogFilters: [],
  dialogFiltersApplied: [],
  showOpStructureFilters: false,
};

const ReportFiltersContext = createContext<ReportFiltersContextType<unknown>>(initialState);

export interface ReportFiltersProviderProps<TGroupBy> {
  children: ReactNode;
  groupByConfig?: ReportFiltersContextType<TGroupBy>['groupByConfig'];
  discountsConfig?: ReportFiltersContextType<TGroupBy>['discountsConfig'];
  loadingSelector: ReportFiltersContextType<TGroupBy>['loadingSelector'];
  dialogFilters?: ReportFiltersContextType<TGroupBy>['dialogFilters'];
  dialogFiltersApplied?: ReportFiltersContextType<TGroupBy>['dialogFiltersApplied'];
  applyAction: ReportFiltersContextType<TGroupBy>['applyAction'];
  onedriveAction?: ReportFiltersContextType<TGroupBy>['onedriveAction'];
  showOpStructureFilters?: ReportFiltersContextType<TGroupBy>['showOpStructureFilters'];
}

const ReportFiltersProvider = <TGroupBy extends string | unknown>({
  groupByConfig,
  discountsConfig,
  loadingSelector,
  applyAction,
  dialogFilters,
  dialogFiltersApplied,
  onedriveAction,
  showOpStructureFilters,
  ...others
}: ReportFiltersProviderProps<TGroupBy>) => {
  const value = useMemo(
    () => ({
      groupByConfig,
      discountsConfig,
      loadingSelector,
      applyAction,
      dialogFilters,
      dialogFiltersApplied,
      onedriveAction,
      showOpStructureFilters,
    }),
    [
      groupByConfig,
      discountsConfig,
      loadingSelector,
      applyAction,
      dialogFilters,
      dialogFiltersApplied,
      onedriveAction,
      showOpStructureFilters,
    ]
  );

  return <ReportFiltersContext.Provider value={value} {...others} />;
};

export const useReportFilters = <TGroupBy extends string>() => {
  const context = useContext(ReportFiltersContext) as ReportFiltersContextType<TGroupBy>;

  if (context === undefined)
    throw new Error('useReportFilters debe estar dentro de ReportFiltersContext.Provider');

  return context;
};

export default ReportFiltersProvider;
