import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ThirdPartySalesByItems } from '../../types';
import {
  reportFilterCountryCodeSelector,
  reportThirdPartySalesByItemsGroupBySelector,
  reportThirdPartySalesByItemsSelector,
} from '../../state';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { ThirdPartySalesByItemsDataGridContext } from './handlerFactory';

const DataGridConfig = () => {
  const { t } = useTranslation();
  const { handler } = useDataGridFlexSales<
    ThirdPartySalesByItems,
    ThirdPartySalesByItemsDataGridContext
  >();
  const country = useSelector(reportFilterCountryCodeSelector);
  const { loading, data } = useSelector(reportThirdPartySalesByItemsSelector);
  const groupBy = useSelector(reportThirdPartySalesByItemsGroupBySelector);

  const gridContext: ThirdPartySalesByItemsDataGridContext = useMemo(
    () => ({
      t,
      country,
      groupBy,
    }),
    [t, country, groupBy]
  );

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setTitle(t('thirdPartySalesByItems.title'));
  handler.setLoading(loading);

  return null;
};

const ThirdPartySalesByItemsDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default ThirdPartySalesByItemsDataGrid;
