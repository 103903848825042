import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { SimpleSelect, SimpleSelectProps } from '../../selects';
import { ClosingFranchiseType } from '../../../types';
import {
  changeClosingFilterFranchiseType,
  reportClosingFilterFranchiseTypeSelector,
} from '../../../state';

type FranchiseTypeFilterProps = Pick<SimpleSelectProps, 'fullWidth'>;

const FranchiseTypeFilter = (props: FranchiseTypeFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const franchiseType = useSelector(reportClosingFilterFranchiseTypeSelector);
  const options = [
    { id: ClosingFranchiseType.Y, description: 'LICO' },
    { id: ClosingFranchiseType.N, description: 'OPCO' },
  ];

  const handleChange = useCallback(
    (value: string) => dispatch(changeClosingFilterFranchiseType(value as ClosingFranchiseType)),
    [dispatch]
  );

  return (
    <SimpleSelect
      {...props}
      name="franchiseType"
      options={options}
      value={franchiseType}
      label={t('toolbar.labels.franchiseType')}
      onChange={handleChange}
      clearable
    />
  );
};

export default FranchiseTypeFilter;
