import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GCsByProduct } from '../../types';

import {
  reportFilterCountryCodeSelector,
  reportFilterDiscountsJoinSelector,
  reportGCsByProductSelector,
  reportMissingClosingTotalMissingClosingTotalSelector,
  reportSalesTypesSelector,
} from '../../state';

import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { GCsByProductDataGridContext } from './handlerFactory';

const DataGridTitle = () => {
  const { t } = useTranslation();
  const { loaded, data: totals } = useSelector(
    reportMissingClosingTotalMissingClosingTotalSelector
  );
  let title = t('gcs-by-product.title');

  if (loaded && totals !== null) {
    title += ` (${totals} ${t('sale-totals.labels.missing')})`;
  }
  return <span>{title}</span>;
};

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<GCsByProduct, GCsByProductDataGridContext>();
  const { t } = useTranslation();
  const country = useSelector(reportFilterCountryCodeSelector);
  const { data: saleTypeOptions } = useSelector(reportSalesTypesSelector);
  const discountsJoin = useSelector(reportFilterDiscountsJoinSelector);
  const { loading, data } = useSelector(reportGCsByProductSelector);
  const titles = useMemo(
    () => ({ title: t('gcs-by-product.title'), tableTitle: <DataGridTitle /> }),
    [t]
  );

  const gridContext = useMemo(
    () => ({
      t,
      country,
      saleTypeOptions,
      discountsJoin,
    }),
    [t, country, saleTypeOptions, discountsJoin]
  );

  handler.setTitle(titles);
  handler.setContext(gridContext);
  handler.setLoading(loading);
  handler.setData(data);

  return null;
};

const GCsByProductDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default GCsByProductDataGrid;
