import { collectionsAxios } from '../config/axiosInstances';
import { FindClosingStatusRequest, FindClosingStatusResponse, MissingCauses } from '../types';
import { FindClosingRequest, FindClosingResponse } from './SalesApi';

export interface FindCollectionsMissingClosingDetailRequest {
  countryAcronym: string;
  storeAcronym?: string;
  accountingDate?: string;
}

export type FindCollectionsMissingClosingDetailResponse = {
  country: string;
  store: string;
  business_date: string;
  cause: string[];
}[];

export interface FindCollectionsMissingClosingCausesRequest {
  countryAcronym: string;
  storeAcronym: string;
  accountingDate?: string;
  cause: string;
}

export type CollectionsMissingTypeCauseResponse = {
  code: number;
  error_value: number;
};

export type CollectionsDifferencesCauseResponse = {
  transfer_type: string;
  sequence: number;
  close_transfer_qty: number;
  detail_transfer_qty: number;
};

export type FindCollectionsMissingClosingCausesResponse = {
  country: string;
  store: string;
  business_date: string;
  cause: string;
  details: CollectionsMissingTypeCauseResponse[] | CollectionsDifferencesCauseResponse[];
}[];

export interface FindReliefsRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  groupBy?: string;
}

export type FindReliefsResponse = {
  country: string;
  store?: string;
  business_date?: string;
  quantity: number;
  amount: number;
}[];

export interface FindPettyCashDifferenceRequest {
  countryAcronym: string;
  storeAcronym?: string;
  businessDateRange?: string;
  differenceType?: string;
  sentToOnedrive: boolean;
}

export type FindPettyCashDifferenceBaseResponse = {
  country: string;
  store: string;
  transfer_id: number;
  transfer_type_id: string;
  business_date: string;
  operation_date: string;
  transfer_time?: string;
  pos_num: number;
  shift_num: number;
  user_id: number;
  username: string;
  employee_id: number;
  employee_name: string;
  total_amt: number;
};

export type FindPettyCashDifferenceResponse = FindPettyCashDifferenceBaseResponse[];

export type FindPettyCashDifferenceByPaymentTypeRequest = FindPettyCashDifferenceRequest & {
  tenderName?: string;
};

export type FindPettyCashDifferenceByPaymentTypeResponse = (FindPettyCashDifferenceBaseResponse & {
  tender_name: string;
  amount: number;
  foreign_amount: number;
  fc_rate: number;
})[];

export default class {
  static async findClosingCollectionsStatus(request: FindClosingStatusRequest) {
    return collectionsAxios.get<FindClosingStatusResponse>(`/closing_status`, {
      params: request,
    });
  }

  static async findClosing(request: FindClosingRequest) {
    return collectionsAxios.get<FindClosingResponse>(`/missing_closing_detail`, {
      params: request,
    });
  }

  static async findCauseTypes() {
    return collectionsAxios.get<MissingCauses[]>(`/missing_closing_causes_types`);
  }

  static async findCollectionsMissingClosingCauses(
    request: FindCollectionsMissingClosingCausesRequest
  ) {
    return collectionsAxios.get<FindCollectionsMissingClosingCausesResponse>(
      `/missing_closing_causes`,
      {
        params: request,
      }
    );
  }

  static async findReliefs(request: FindReliefsRequest) {
    return collectionsAxios.get<FindReliefsResponse>(`/reliefs`, {
      params: request,
    });
  }

  static async findPettyCashDifference(request: FindPettyCashDifferenceRequest) {
    return collectionsAxios.get<FindPettyCashDifferenceResponse>(`/petty_cash_difference`, {
      params: request,
    });
  }

  static async findPettyCashDifferenceByPaymentType(
    request: FindPettyCashDifferenceByPaymentTypeRequest
  ) {
    return collectionsAxios.get<FindPettyCashDifferenceByPaymentTypeResponse>(
      `/petty_cash_difference_by_payment_type`,
      {
        params: request,
      }
    );
  }
}
