import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reportGcsPaymentTypesSelector, reportFilterCountryCodeSelector } from '../../state';
import { DataGridFlexSales, useDataGridFlexSales } from '../DataGridFlexSales';
import handlerFactory, { GcsByPaymentTypeDataGridContext } from './handlerFactory';
import { GcsByPaymentTypes } from '../../types';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<GcsByPaymentTypes, GcsByPaymentTypeDataGridContext>();
  const { t } = useTranslation();
  const country = useSelector(reportFilterCountryCodeSelector);
  const gridContext = useMemo(() => ({ t, country }), [t, country]);
  const { loading, data } = useSelector(reportGcsPaymentTypesSelector);

  handler.setTitle(t('gcs-payment-type.title'));
  handler.setContext(gridContext);
  handler.setLoading(loading);
  handler.setData(data);

  return null;
};

const GcsByPaymentTypeDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory}>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default GcsByPaymentTypeDataGrid;
